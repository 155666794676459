<template>
  <i class="pgn-arrow-d-left-icon icon">
    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.93173 7.46674L5.69268 4.22096C5.57083 4.09912 5.57083 3.90087 5.69291 3.77879L8.93171 0.533246C9.05363 0.411074 9.05341 0.213215 8.93126 0.0913087C8.80909 -0.0306131 8.61123 -0.03041 8.48932 0.0917619L5.25074 3.33709C4.88521 3.70262 4.88521 4.29739 5.25051 4.66268L8.48932 7.90823C8.55037 7.9694 8.63043 7.99999 8.71052 7.99999C8.79038 7.99999 8.87027 7.96956 8.93127 7.90868C9.05343 7.78678 9.05363 7.58892 8.93173 7.46674Z"
        fill="#271118"
      />
      <path
        d="M5.93173 7.46674L2.69268 4.22096C2.57083 4.09912 2.57083 3.90087 2.69291 3.77879L5.93171 0.533246C6.05363 0.411074 6.05341 0.213215 5.93126 0.0913087C5.80909 -0.0306131 5.61123 -0.03041 5.48932 0.0917619L2.25074 3.33709C1.88521 3.70262 1.88521 4.29739 2.25051 4.66268L5.48932 7.90823C5.55037 7.9694 5.63043 7.99999 5.71052 7.99999C5.79038 7.99999 5.87027 7.96956 5.93127 7.90868C6.05343 7.78678 6.05363 7.58892 5.93173 7.46674Z"
        fill="#271118"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "PaginationDArrowLeftIcon",
};
</script>
