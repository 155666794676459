<template>
  <i class="question-icon icon">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16 8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114 0 8 0ZM8 12.6666C7.63196 12.6666 7.33337 12.368 7.33337 12C7.33337 11.632 7.63196 11.3334 8 11.3334C8.36804 11.3334 8.66663 11.632 8.66663 12C8.66663 12.368 8.36804 12.6666 8 12.6666ZM9.0553 8.42798C8.81934 8.53662 8.66663 8.77466 8.66663 9.03406V9.33337C8.66663 9.70129 8.36865 10 8 10C7.63135 10 7.33337 9.70129 7.33337 9.33337V9.03406C7.33337 8.25598 7.79065 7.54272 8.4967 7.21667C9.17603 6.90405 9.66663 6.07397 9.66663 5.66663C9.66663 4.74805 8.91931 4 8 4C7.08069 4 6.33337 4.74805 6.33337 5.66663C6.33337 6.03467 6.03528 6.33337 5.66663 6.33337C5.29797 6.33337 5 6.03467 5 5.66663C5 4.0127 6.34595 2.66663 8 2.66663C9.65405 2.66663 11 4.0127 11 5.66663C11 6.56738 10.2186 7.89136 9.0553 8.42798Z"
        fill="#2A3742"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "QuestionIcon",
};
</script>
