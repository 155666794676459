<template>
  <i class="pgn-arrow-right-icon icon">
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.06827 0.533255L5.30732 3.77904C5.42917 3.90088 5.42917 4.09913 5.30709 4.22121L2.06829 7.46675C1.94637 7.58893 1.94659 7.78678 2.06874 7.90869C2.19091 8.03061 2.38877 8.03041 2.51068 7.90824L5.74926 4.66291C6.11479 4.29738 6.11479 3.70261 5.74949 3.33732L2.51068 0.0917708C2.44963 0.0305988 2.36957 5.40261e-06 2.28948 5.39561e-06C2.20962 5.38863e-06 2.12973 0.0304429 2.06873 0.0913178C1.94657 0.213224 1.94637 0.411083 2.06827 0.533255Z"
        fill="#271118"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "PaginationArrowRightIcon",
};
</script>
