<template>
  <i class="success-icon icon">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M8 16C12.411 16 16 12.4113 16 8C16 7.23861 15.8937 6.48828 15.6833 5.76969C15.5796 5.41634 15.2106 5.21338 14.8563 5.31706C14.5026 5.42041 14.3003 5.79069 14.4036 6.14404C14.578 6.74105 14.6667 7.36572 14.6667 8C14.6667 11.6759 11.6759 14.6667 8 14.6667C4.32406 14.6667 1.33333 11.6759 1.33333 8C1.33333 4.32406 4.32406 1.33333 8 1.33333C9.33594 1.33333 10.6213 1.72396 11.717 2.46273C12.022 2.66862 12.4367 2.58773 12.6423 2.28271C12.848 1.97738 12.7677 1.56299 12.4624 1.35726C11.1457 0.469401 9.6027 0 8 0C3.58903 0 0 3.5887 0 8C0 12.4113 3.58903 16 8 16Z"
          fill="#FF8000"
        />
        <path
          d="M14.8612 1.52869L7.99923 8.39034L5.80391 6.19535C5.5435 5.9351 5.12162 5.9351 4.86121 6.19535C4.60095 6.45577 4.60095 6.87765 4.86121 7.13806L7.52787 9.80473C7.65824 9.9351 7.82849 10 7.99923 10C8.16996 10 8.34021 9.9351 8.47058 9.80473L15.8039 2.47139C16.0642 2.21098 16.0642 1.7891 15.8039 1.52869C15.5435 1.26843 15.1216 1.26843 14.8612 1.52869Z"
          fill="#FF8000"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </i>
</template>

<script>
export default {
  name: "SuccessIcon",
};
</script>
